@font-face {
    font-family: SFProText-Bold;
    src: url(https://cdn.glitch.com/d0b37ad1-2a33-4aeb-81f8-580d20e9c13c%2FSF-Pro-Text-Bold.otf?1540850572130);
}

@font-face {
    font-family: SFProText-Regular;
    src: url(https://cdn.glitch.com/d0b37ad1-2a33-4aeb-81f8-580d20e9c13c%2FSF-Pro-Text-Regular.otf?1540850573775);
}

@font-face {
    font-family: SFProText-Medium;
    src: url(https://cdn.glitch.com/d0b37ad1-2a33-4aeb-81f8-580d20e9c13c%2FSF-Pro-Text-Medium.otf?1540850573837);
}

@font-face {
    font-family: SFProText-Light;
    src: url(https://cdn.glitch.com/d0b37ad1-2a33-4aeb-81f8-580d20e9c13c%2FSF-Pro-Text-Light.otf?1540850632944);
}

* {
  font-family: SFProText-Regular, "Roboto", Helvetica, Century Gothic, Arial sans-serif;
}
